<template>
  <nav id="menu" class="menu" :style="`background-image: -webkit-linear-gradient(-15deg, black 50%, ${color} 50%);`">

    <div class="menu__izquierda">
      <img loading="lazy" width="79" height="20" class="menu__logo"
        src="https://d3jotkb1j9zbaa.cloudfront.net/adiro-logo.svg" alt="Logo Adiro del menu" @click="subirScroll" />

      <div class="menu__paginas">
        <a href="/adiro">Adiro / </a>
        <a href="/la-felguera">La Felguera / </a>
        <a href="/epil">ePIL / </a>
      </div>
    </div>
    <div class="menu__derecha" :style="`background-color: ${color};`">
      <a href="https://www.bayer.com/es/es/espana-home">
        <p class="menu__links menu__links--principal">BAYER ESPAÑA</p>
      </a>
      <a href="https://www.bayer.com/es/es/espana-contacto">
        <p class="menu__links menu__links--normal">Contacto</p>
      </a>
    </div>

    <div class="menu__movil">
      <div class="menu__barras" @click="menu = !menu" :class="menu ? 'open' : ''">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="menu__paginas--movil" :class="menu ? 'open' : ''" :style="`background-color: ${color};`">
        <a href="/adiro">Adiro</a>
        <a href="/la-felguera" >La Felguera</a>
        <a href="/epil">ePIL</a>
      </div>

    </div>
  </nav>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router'
export default {
  name: "menuPrincipal",
  data() {
    return {
      menu: false,
    }
  },
  setup() {
    const route = useRoute();
    const color = computed(() => route.name == 'LaFelguera' ? '#00bbff' : route.name == 'Adiro' ? '#e30036' : '#50B162');
    return { color };
  },
  mounted() {
    window.addEventListener("scroll", this.cambiarColorMenu);
  },
  methods: {
    subirScroll() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    cambiarColorMenu() {
      if (this.$route.name.toLowerCase() == "adiro"
        || this.$route.name.toLowerCase() == "lafelguera"
        || this.$route.name.toLowerCase() == "epil") {

        const alturaB1 = document.querySelector("#BloqueUnoPrincipal").scrollHeight;
        const alturaB3 = document.querySelector("#BloqueUnoPrincipal").scrollHeight + document.querySelector("#BloquedosPrincipal").scrollHeight;
        let posScroll = document.documentElement.scrollTop;
        const alturaMenu = document.querySelector("#menu").clientHeight;

        if ((posScroll + alturaMenu) < alturaB1 || (posScroll + alturaMenu) > alturaB3) {
          document.querySelector(".menu__barras").style.filter = "invert(0)";
        } else {
          document.querySelector(".menu__barras").style.filter = "invert(1)";
        }
        
      }

    }
  },
};
</script>